body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #010718;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  display: flex;
  justify-content: stretch;
  align-items: center;
  max-width: 900px;
  width: 100%;
  background: #2a3555;
  padding: 2rem;
  border-radius: .5rem;
  color: white;
  box-sizing: border-box;
}

.type {
  color:#0000;
  background:
    linear-gradient(-90deg,#00DFFC 5px,#0000 0) 10px 0,
    linear-gradient(#00DFFC 0 0) 0 0;
  background-size:calc(var(--n)*1ch) 200%;
  -webkit-background-clip:padding-box,text;
  background-clip:padding-box,text;
  background-repeat:no-repeat;
  animation: 
   b .7s infinite steps(1),   
   t calc(var(--n)*.05s) steps(var(--n)) forwards;
}

@keyframes t{
  from {background-size:0 200%}
}
@keyframes b{
  50% {background-position:0 -100%,0 0}
}

ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: .5rem;
  box-sizing: border-box;
}

li {
  list-style-type: none;
  width: 100%;
  box-sizing: border-box;
  background: #010718;
  padding: 1rem;
  font-weight: 700;
  border-radius: .5rem;
  cursor: pointer;
}

li p {
  overflow: hidden; /* Ensures the text is hidden until animated */
  border-right: .15em solid black; /* The cursor */
  white-space: nowrap; /* Keeps the text on a single line */
  letter-spacing: .05em; /* Adjusts the spacing between letters */
  margin: 0;
  animation: 
      typewriter 3s steps(40, end), /* Adjust the duration and steps as needed */
      blink-caret .75s step-end infinite;
}
